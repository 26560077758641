import { Component, Mixins, Watch } from 'vue-property-decorator';

import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BarChart from '@/chartcomponents/barcomponent/BarComponent.vue';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import DeviceWidthCheckerMixin from '@/mixins/deviceWidthChecker.mixin';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';
import LineChart from '@/chartcomponents/linecomponent/LineChart.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import analyticsStore from '@/store/modules/analyticsStore';

@Component({
  components: {
    'multi-select-dropdown': MultiSelectDropdown,
    'line-chart': LineChart,
    'bar-chart': BarChart,
    'dropdown-list': DropdownList,
    'bouncing-preloader': BouncingPreloaderComponent,
  }
})


export default class CompletionAttendanceAnalyticsComponent extends Mixins(DeviceWidthCheckerMixin) {

  public singleSiteDropdown = APP_CONST.ATTENDANCE_SITES_CONFIG;
  public multiGroupsDropdown = APP_CONST.ATTENDANCE_GROUPS_CONFIG;
  public masterTabsValue = APP_CONST.SCHOLAR_ATTENDANCE_DAYS_SWITCH_TAB;
  public yScaleLabel = '(%) Percent';
  public yAxisLabelString: string = 'Percent (%)';
  public xAxisLabelString: string = '';
  public isDisplayXLabel: boolean = false;
  public barChartBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public barChartHoverBackground: string = APP_CONST.STYLE.COLOR.BLUE_700;
  public isChartMount: boolean = false;
  public isStaffNoGroupSelected: boolean = false;
  public highestRole: number = JSON.parse(JSON.stringify(APP_UTILITIES.getCookie('highest_role')));
  public onlyOneSiteAvailable: boolean = false;
  public isMobileView: boolean = false;
  public isBarChartLoaderVisible: boolean = false;
  public barChartResponse: boolean = false;

  @Watch('selectedProgramData', { deep: true })
  updateProgramData(programData: any) {
    this.isBarChartLoaderVisible = true;
    analyticsStore.completionBarChartLoaderVisible(true);
    analyticsStore.completionLineChartVisible(false);
    this.barChartResponse = false;
    if (programData && programData.hasOwnProperty('programId') && programData.programId && !this.isChartMount) {
      analyticsStore.mutateGroupsList([]);
      this.getProgramSites(programData);
      analyticsStore.getPersistenceScholarTab({ selectedTab: 'completion', programId: programData.programId }).then(() => {
        analyticsStore.fetchCompletionSiteGroupsAnalyticsData({ programId: programData.programId, siteId: (this.selectedSite ? this.selectedSite.id : '') });
      });
      analyticsStore.getProgramAttendenceCompletionBarSites(programData.programId).then(() => {
        this.isBarChartLoaderVisible = false;
        analyticsStore.completionBarChartLoaderVisible(false);
        this.barChartResponse = true;
      });
    }
    this.isChartMount = false;
  }

  get isBarChartVisible() {
    const currentRole: number = Number(APP_UTILITIES.getCookie('highest_role'));
    return ![APP_CONST.ROLE_TYPE_SESSION_ADMIN, APP_CONST.ROLE_TYPE_STAFF].includes(currentRole);
  }

  get isCompletionLineChartVisible() {
    return analyticsStore.isCompletionLineChartVisible;
  }

  get barEmtyStateMessage() {
    let emptyStateMessage = APP_CONST.BLANK;
    const sum: number = 0;
    if (!this.sitesDropdown.length) {
      emptyStateMessage = APP_CONST.NO_SITE_SESSION_DATA;
    }
    else if ((this.complProgramPercentage == '--') || (this.complProgramPercentage == '0%')) {
      emptyStateMessage = APP_CONST.AWAITING_ATTENDANCE_INPUT;
    }
    return emptyStateMessage;
  }

  get complProgramPercentage() {
    return (this.sitesDropdown && this.sitesDropdown.length) ? analyticsStore.getComplProgramPercentage : '--';
  }

  get selectedAttendanceAnalyticsTab() {
    return analyticsStore.selectedAttendanceAnalyticsTabValue;
  }

  get selectedTab() {
    return analyticsStore.selectedSiteSessionAnalyticsTabValue;
  }

  get selectedProgramData() {
    return analyticsStore.slectedProgram;
  }

  get groupsDropdown() {
    return analyticsStore.siteGroupsList;
  }

  get sitesDropdown() {
    return analyticsStore.selectedprogramSites;
  }

  get selectedSiteIds() {
    return analyticsStore.selectedSites;
  }

  get isDisabledDropdown() {
    return this.sitesDropdown && ((this.sitesDropdown.length == APP_CONST.ZERO) || (this.groupsDropdown.length == APP_CONST.ZERO));
  }

  get isSiteDisabled() {
    return this.sitesDropdown && ((this.sitesDropdown.length == APP_CONST.ZERO) || (this.sitesDropdown.length == APP_CONST.ONE));
  }

  get attendenceData() {
    return analyticsStore.attendenceCompletionDetails;

  }

  get lineChartStyle() {
    let allApplicationLength: number = 0;
    if (this.selectedTab == 'Week') {
      allApplicationLength = this.attendenceData && this.attendenceData.labels && this.attendenceData.labels.length ? this.attendenceData.labels.length * (this.attendenceData.labels.length * 10 > 110 ? 110 : this.attendenceData.labels.length * 10) : 0;
    } else {
      allApplicationLength = this.attendenceData && this.attendenceData.labels && this.attendenceData.labels.length ? this.attendenceData.labels.length * (this.attendenceData.labels.length * 5 > 50 ? 50 : this.attendenceData.labels.length * 5) : 0;
    }
    const styles: any = {
      width: allApplicationLength > 1280 ? `${allApplicationLength}px` : '100%',
      height: '400px',
      position: 'relative'
    };
    return styles;
  }


  get selectedSite() {
    return analyticsStore.selectedSiteData;
  }

  get emptyStateMessage() {
    this.isStaffNoGroupSelected = false;
    if (this.highestRole == APP_CONST.ROLE_TYPE_STAFF && this.selectedSite) {
      if (this.selectedGroupIds && !this.selectedGroupIds.length) {
        this.isStaffNoGroupSelected = true;
      }
    }
    return (!this.selectedSite || (this.selectedSite && !this.selectedSite.id)) ? APP_CONST.NO_SITE_SESSION : (this.isChartDataAvailable && !this.isStaffNoGroupSelected ? APP_CONST.AWAITING_ATTENDANCE_INPUT : '');
  }

  get isChartDataAvailable() {
    return this.attendenceData && this.attendenceData.datasets && Object.keys(this.attendenceData.datasets).every((element: any) => this.attendenceData.datasets[element].data && !this.attendenceData.datasets[element].data.length);
  }

  get selectedGroupIds() {
    return analyticsStore.selectedGroupIdsList;
  }

  get barChartStyle() {
    let allApplicationLength: number = 0;
    if (this.isMobile) {
      allApplicationLength = (this.barChartData && this.barChartData.length) ? this.barChartData.length * (this.barChartData.length * 50 > 110 ? 110 : this.barChartData.length * 50) : 0;
    } else {
      allApplicationLength = (this.barChartData && this.barChartData.length) ? this.barChartData.length * (this.barChartData.length * 10 > 110 ? 110 : this.barChartData.length * 10) : 0;
    }
    const styles: any = {
      width: allApplicationLength > document.documentElement.clientWidth ? `${allApplicationLength}px` : '100%',
      height: '400px',
      position: 'relative'
    };
    return styles;
  }

  get barChartData() {
    return analyticsStore.barCompletionAnalyticalData;
  }

  /**
   * Fetch api data on the basis of selected sites
   *
   * @param eventData
   */
  selectSite(eventData: { id: number; value: string }) {
    analyticsStore.completionLineChartVisible(false);
    analyticsStore.mutateSelectedGroupIds([]);
    setTimeout(() => {
      if (eventData && eventData.id >= 0 && eventData.value) {
        analyticsStore.mutateSelectedSite({ ...eventData, programId: this.selectedProgramData.programId });
        analyticsStore.fetchCompletionSitesAnalyticsData({ programId: this.selectedProgramData.programId, siteId: eventData.id }).then((response) => {
          analyticsStore.completionCsvEnableDisableItems();
          analyticsStore.mutateAttendanceSiteSession({ programId: this.selectedProgramData.programId, site: eventData, groups: this.groupsDropdown });
        });
      } else if (eventData && eventData.hasOwnProperty('id') && (eventData.id < APP_CONST.ONE)) {
        analyticsStore.mutateSelectedSite({ id: 0, value: '' });
        analyticsStore.mutateGroupsList([]);
        analyticsStore.completionCsvEnableDisableItems();
        analyticsStore.mutateAttendanceSiteSession({ programId: this.selectedProgramData.programId, site: eventData, groups: [] });
      }
    }, 500);
  }

  /**
   * Fetch api data on the basis of selected sites and groups
   *
   * @param eventData
   */
  selectGroup(eventData: any) {
    if (eventData.selectedValue != undefined) {
      analyticsStore.mutateAttendanceSiteSession({ programId: this.selectedProgramData.programId, site: { id: this.selectedSite.id, value: this.selectedSite.value }, groups: eventData.dropdownList });
    }
  }

  /**
   * Api call for selected program sites
   *
   */
  getProgramSites(selectedProgram: any) {
    if (selectedProgram && selectedProgram.programId) {
      analyticsStore.getProgramAttendenceCompletionSites(selectedProgram.programId);
    }
  }

  /**
   * Api call on program change
   *
   * @param selectedProgram
   */
  selectProgramData(selectedProgram: any) {
    APP_UTILITIES.setFilteredColumns('enrollmentselectedprogram', selectedProgram);
    analyticsStore.getProgramAttendenceCompletionSites(selectedProgram.programId);
  }

  /**
   * Switch days tab
   *
   * @param tab
   */
  tabSwitch(tab: any) {
    /* istanbul ignore else */
    analyticsStore.completionLineChartVisible(false);
    if (this.selectedTab != tab.name) {
      APP_UTILITIES.setFilteredColumns('completionTabSelection', tab);
      analyticsStore.mutateAnalyticsSiteSessionTabSwitch(tab.name);
      analyticsStore.fetchCompletionSiteGroupsAnalyticsData({ programId: this.selectedProgramData.programId, siteId: (this.selectedSite ? this.selectedSite.id : '') });
    }
  }

  /**
   * Return label name as per its ID
   *
   * @param groupId
   * @returns
   */
  getLabelName(groupId: number) {
    let labelName: string = '';
    this.groupsDropdown.forEach((group: any) => {
      if (group.id == groupId) {
        labelName = group.value;
      }
    });
    return labelName;
  }

  multiSelectDropdownClosed() {
    analyticsStore.completionLineChartVisible(false);
    analyticsStore.fetchCompletionSiteGroupsAnalyticsData({ programId: this.selectedProgramData.programId, siteId: this.selectedSite.id });
  }


  destroyed() {
    analyticsStore.resetSelectedProgramPerc();
  }

  beforeMount() {
    this.isBarChartLoaderVisible = true;
    analyticsStore.completionBarChartLoaderVisible(true);
    analyticsStore.completionLineChartVisible(false);
    this.barChartResponse = false;
    this.isMobileView = APP_UTILITIES.isMobile();
    analyticsStore.mutateAnalyticsAttendanceTabSwitch('Completion');
    if (APP_UTILITIES.getFilteredColumns('completionTabSelection')) {
      let storedTab: any = APP_UTILITIES.getFilteredColumns('completionTabSelection');
      storedTab = JSON.parse(storedTab);
      analyticsStore.mutateAnalyticsSiteSessionTabSwitch(storedTab.name);
    } else {
      analyticsStore.mutateAnalyticsSiteSessionTabSwitch('Week');
    }
    this.isChartMount = true;
    if (APP_UTILITIES.getFilteredColumns('attendanceselectedprogram')) {
      const program: any = APP_UTILITIES.getFilteredColumns('attendanceselectedprogram');
      const selectedProgram = JSON.parse(program);
      analyticsStore.mutateSelectedProgramName(selectedProgram);
      analyticsStore.mutateSelectedGroups([]);
      analyticsStore.mutateSelectedSite({ id: 0, value: '' });
      analyticsStore.completionCsvEnableDisableItems();
      this.getProgramSites(selectedProgram);
      if (selectedProgram && selectedProgram.programId) {
        analyticsStore.getPersistenceScholarTab({ selectedTab: 'completion', programId: selectedProgram.programId }).then(() => {
          analyticsStore.fetchCompletionSiteGroupsAnalyticsData({ programId: selectedProgram.programId, siteId: (this.selectedSite ? this.selectedSite.id : '') });
        });
        analyticsStore.getProgramAttendenceCompletionBarSites(selectedProgram.programId).then(() => {
          this.isBarChartLoaderVisible = false;
          analyticsStore.completionBarChartLoaderVisible(false);
          this.barChartResponse = true;
        });
      }
    } else {
      analyticsStore.programNameSelection().then(() => {
        this.getProgramSites(this.selectedProgramData);
        analyticsStore.mutateSelectedSite({ id: 0, value: '' });
        analyticsStore.completionCsvEnableDisableItems();
        if (this.selectedProgramData && this.selectedProgramData.programId) {
          analyticsStore.getPersistenceScholarTab({ selectedTab: 'completion', programId: this.selectedProgramData.programId }).then(() => {
            analyticsStore.fetchCompletionSiteGroupsAnalyticsData({ programId: this.selectedProgramData.programId, siteId: (this.selectedSite ? this.selectedSite.id : '') });
          });
          analyticsStore.getProgramAttendenceCompletionBarSites(this.selectedProgramData.programId).then(() => {
            this.isBarChartLoaderVisible = false;
            analyticsStore.completionBarChartLoaderVisible(false);
            this.barChartResponse = true;
          });
        }
      });

    }


  }
}
